<template>
	<div>
		<v-app-bar color="primary" dark>
			<v-icon>mdi-barcode</v-icon>
			<v-toolbar-title class="ml-3">RETROOZ INPUT TOOL</v-toolbar-title>

			<v-spacer />

			<v-menu transition="scale-transition">
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon>people</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item @click="admin">
						<v-list-item-title>Retrooz admin</v-list-item-title>
					</v-list-item>
					<v-list-item @click="logout">
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>
		<v-main v-if="isAuthenticated">
			<v-container class="product-list-container" fluid>
				<v-row>
					<v-col cols="12" md="12" sm="12" xs="12">
						<v-card class="product-list mt-5">
							<v-card-title>
								<v-row>
									<v-col cols="12" md="4" sm="12" xs="12" class="d-flex align-end">
										<v-btn @click.stop="clickNewProduct()" outlined tile color="teal">
											<v-icon left>mdi-plus</v-icon>
											Create new product
										</v-btn>
									</v-col>
									<v-col cols="12" md="4" sm="6" xs="12" class="d-flex align-center">						
										<v-select
											v-model="selectedFilter"
											:items="filters"
											item-text="text"
											item-value="value"
											label="Filter by"
											@change="setFilter"
											hide-details
										></v-select>
									</v-col>
									<v-col cols="12" md="4" sm="6" xs="12" class="d-flex align-center">
										<v-text-field
											v-model="search"
											append-icon="search"
											label="Search"
											single-line
											hide-details
											clearable
											@click:clear="getProducts"
											@keyup="searchProducts"
										></v-text-field>
									</v-col>
								</v-row>
							</v-card-title>
							<v-data-table
								:headers="headers"
								:items="products"
								:options.sync="options"
								:server-items-length="totalProducts"
								:loading="loading"
								class="elevation-1"
							>
								<template v-slot:[`item.image`]="{ item }">
									<span class="info-label text-caption text-center">{{ item.sku }}</span>
									<v-img
										:lazy-src="item.thumbnail"
										:src="item.thumbnail"
										:width="imageHeight"
										class="mb-2"
										@click.stop="clickImage(item)"
									>
										<template v-slot:placeholder>
											<v-row
												class="fill-height ma-0"
												align="center"
												justify="center"
											>
												<v-progress-circular
													indeterminate
													color="grey lighten-5"
												></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</template>
								<template v-slot:[`item.online`]="{ item }">
									<v-checkbox
										v-model="item.published"
										color="primary"
										@click.capture="quickChange"
									></v-checkbox>
								</template>
								<template v-slot:[`item.name`]="{ item }">
									<span class="info-label text-caption">Product name</span>
									<p>{{ item.name }}</p>
									<span class="info-label text-caption">Stock location</span>
									<p>{{ item.stockLocation }}</p>
								</template>
								<template v-slot:[`item.price`]="{ item }">
									<v-text-field
										v-model.number="item.price"
										label="Price"
										clearable
										prefix="€"
										min="0"
										type="number"
										@keyup="quickChange"
									></v-text-field>
								</template>
								<template v-slot:[`item.stock`]="{ item }">
									<v-text-field
										v-model="item.stockQuantity"
										label="Stock"
										clearable
										min="0"
										type="number"
										@keyup="quickChange"
										:rules="[(v) => !!v || 'Stock is required']"
									></v-text-field>
								</template>
								<template v-slot:[`item.options`]="{ item }">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												text
												icon
												color="teal"
												@click.stop="clickEdit(item)"
												v-on="on"
											>
												<v-icon>mdi-file-edit-outline</v-icon>
											</v-btn>
										</template>
										<span>Edit product</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												text
												icon
												color="pink"
												@click.stop="clickDeleteItem(item)"
												v-on="on"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</template>
										<span>Delete product</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												text
												icon
												color="teal"
												@click.stop="clickUrl(item)"
												v-on="on"
											>
												<v-icon>mdi-web</v-icon>
											</v-btn>
										</template>
										<span>Go to product url</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												text
												icon
												color="teal"
												class="d-none btn-quickedit"
												@click.stop="clickQuickSave(item)"
												v-on="on"
											>
												<v-icon>mdi-content-save-edit-outline</v-icon>
											</v-btn>
										</template>
										<span>Save changes</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-btn
												text
												icon
												color="teal"
												class="d-none btn-revert"
												@click.stop="clickUndo(item)"
												v-on="on"
											>
												<v-icon>mdi-file-undo-outline</v-icon>
											</v-btn>
										</template>
										<span>Revert changes</span>
									</v-tooltip>
								</template>
							</v-data-table>
						</v-card>
					</v-col>
				</v-row>
			</v-container>

			<GalleryModal ref="galleryModal" />

			<v-dialog v-model="closeDialog" max-width="500">
				<v-card>
					<v-card-title class="headline">
						<v-icon large left color="red"> mdi-exclamation </v-icon>
						Delete
					</v-card-title>

					<v-card-text>
						<p>Are you sure you want to delete this product?</p>
					</v-card-text>

					<v-card-actions>
						<v-spacer />

						<v-btn color="primary" text @click="closeDialog = false">
							No
						</v-btn>
						<v-btn color="red" text @click="deleteProduct"> Yes </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<CreateNewModal
				ref="createNewModal"
				:categories="categories"
				:tax-categories="taxCategories"
				:tags="tags"
				@snackbarUpdate="updateSnackbar"
				@updateProducts="getProducts"
				@openNewProduct="openNewProduct"
			/>

			<EditModal
				ref="editModal"
				:categories="categories"
				:tax-categories="taxCategories"
				:tags="tags"
				@snackbarUpdate="updateSnackbar"
				@updateProducts="getProducts"
			/>
		</v-main>
		<v-snackbar
			v-model="snackbar"
			:timeout="snackbarTimeout"
			:top="snackbarTop"
		>
			{{ snackbarText }}
			<v-btn color="pink" text @click="snackbar = false"> Close </v-btn>
		</v-snackbar>
	</div>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
	.product-list-container {
		max-width: 1200px;
	}
}

.product-image {
	width: 150px;
	cursor: pointer;
}

.info-label {
	color: #0009;
}

</style>

<script>
import GalleryModal from "./GalleryModal"
import EditModal from "./EditModal"
import CreateNewModal from "./CreateNewModal.vue"

export default {
	name: "ProductList",
	components: {
		GalleryModal,
		EditModal,
		CreateNewModal,
	},

	data: function () {
		return {
			snackbar: false,
			snackbarText: "",
			snackbarTop: true,
			snackbarTimeout: 5000,
			search: null,
			selectedFilter: 0,
			deleteProductItem: null,
			filters: [
				{ text: "Items visible online", value: 1 },
				{ text: "Items not visible online", value: 2 },
				{ text: "Items with no stock", value: 3 },
				{ text: "Items with no images", value: 4 },
				{ text: "Items with no price", value: 5 },
				{ text: "No filter (reset)", value: 0 },
			],
			closeDialog: false,
			headers: [
				{ text: "IMAGE", sortable: false, value: "image", width: "80", align: "center"},
				{ text: "VISIBLE ONLINE", key: "name", value: "online", sortable: false, width: "10"},
				{ text: "INFO", sortable: false, value: "name"},
				{ text: "PRICE", value: "price", width: "110"},
				{ text: "STOCK", value: "stock", width: "110"},
				{ text: "", value: "options", sortable: false, align: "right", width: "140"},
			],

			totalProducts: 0,
			products: [],
			options: {},
			loading: false,

			categories: [],
			taxCategories: [],
			tags: [],
			isAuthenticated: false,
		}
	},

	async mounted() {
		if (localStorage.getItem("Bearer")) {
			this.isAuthenticated = true
		} else {
			this.$router.push("/")
		}

		// this.getProducts()
		this.getCategoriesAndTags()
	},

	computed: {
		imageHeight: function () {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "100px"
				case "sm":
					return "100px"
				case "md":
					return "150px"
				case "lg":
					return "150px"
				case "xl":
					return "150px"
				default:
					return "100px"
			}
		},
	},

	watch: {
		options: {
			handler() {
				this.getProducts()
			},
			deep: true,
		},
	},

	methods: {
		getProducts: async function () {
			try {
				this.loading = true
				this.products = []
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				const { sortBy, sortDesc, page, itemsPerPage } = this.options
				console.log(this.options)

				let queryParams = `?page=${page - 1}&size=${itemsPerPage}`
				if (sortBy.length === 1) {
					queryParams += `&sortBy=${sortBy[0]}&sortDesc=${sortDesc[0]}`
				}

				if (this.search) {
					queryParams += `&keywords=${this.search}`
				}

				if (this.selectedFilter != 0) {
					queryParams += `&filter=${this.selectedFilter}`
				}

				const products = await this.$http.get(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/GetProducts${queryParams}`,
					config
				)

				this.products = products.data.items
				this.totalProducts = products.data.count
				this.loading = false
			} catch (error) {
				if (error.response.status == 401) {
					localStorage.removeItem("Bearer")
					this.$router.push("/")
				} else {	
					let errorMessage = `${error.response.status}: ${error.response.data}`
					this.updateSnackbar(errorMessage)
				}
			}
		},

		getCategoriesAndTags: async function () {
			try {
				this.loading = true
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				const categoriesAndTags = await this.$http.get(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/GetAllCategoriesAndTags`,
					config
				)

				this.categories = categoriesAndTags.data.categories
				this.taxCategories = categoriesAndTags.data.taxCategories
				this.tags = categoriesAndTags.data.tags
				this.loading = false
			} catch (error) {
				if (error.response.status == 401) {
					console.log("401")
					localStorage.removeItem("Bearer")
					this.$router.push("/")
				} else {				
					let errorMessage = `${error.response.status}: ${error.response.data}`
					this.updateSnackbar(errorMessage)
				}
			}
		},

		searchProducts: function () {
			this.getProducts()
		},

		setFilter: function () {
			this.getProducts()
		},

		updateSnackbar: function (message) {
			(this.snackbar = true), (this.snackbarText = message)
		},

		getUpdatedProduct: async function (productId) {
			try {
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				let updatedProduct = await this.$http.get(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/GetProductById?productId=${productId}`,
					config
				)

				return updatedProduct.data
			} catch (error) {
				if (error.response.status == 401) {
					localStorage.removeItem("Bearer")
					this.$router.push("/")
				} else {	
					let errorMessage = `${error.response.status}: ${error.response.data}`
					this.updateSnackbar(errorMessage)
				}
			}
		},

		openNewProduct: async function (productId) {
			await new Promise(resolve => setTimeout(resolve, 500));
			const newProduct = await this.getUpdatedProduct(productId)
			this.$refs.editModal.show(newProduct)
		},

		quickChange: function (event) {
			if (event.target.type === "number") {
				this.numberChange(event)
			}

			const row = event.target.closest("tr")
			const btnRevert = row.querySelectorAll(".btn-quickedit, .btn-revert")
			btnRevert.forEach(function (element) {
				element.classList.remove("d-none")
			})
		},

		numberChange: function (event) {
			event = event ? event : window.event
			const charCode = event.which ? event.which : event.keyCode
			const ignoreChars = ["@", "#", "!", "$", "%", "^", "&", "*", "(", ")"]

			if (ignoreChars.includes(event.key)) {
				event.preventDefault()
			}

			if (
				(charCode >= 48 && charCode <= 57) ||
				(charCode >= 96 && charCode <= 105) || // number
				(charCode >= 37 && charCode <= 40) || // arrows
				charCode === 46 ||
				charCode === 8 || // backspace or delete
				charCode === 188
			) {
				return true
			} else {
				event.preventDefault()
			}
		},

		deleteProduct: async function () {
			try {
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				await this.$http.post(
					`${process.env.VUE_APP_RETROOZ_URL}/api/api/products/DeleteProduct`,
					this.deleteProductItem,
					config
				)

				this.snackbarText = "Product has been deleted"
				this.snackbar = true
				this.getProducts()
				this.deleteProductItem = null
				this.closeDialog = false
			} catch (error) {
				let errorMessage = `${error.response.status}: ${error.response.data}`
				this.updateSnackbar(errorMessage)
			}
		},

		clickUndo: function () {
			this.getProducts()
		},

		clickQuickSave: async function (item) {
			try {
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				await this.$http.post(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/UpdateProduct`,
					item,
					config
				)

				this.snackbarText = "Changes have been saved"
				this.snackbar = true
			} catch (error) {
				let errorMessage = `${error.response.status}: ${error.response.data}`
				this.updateSnackbar(errorMessage)
			}
		},

		clickNewProduct: function () {
			this.$refs.createNewModal.show({})
		},

		clickUrl: function(item) {
			window.open(item.productUrl, "_blank")
		},

		clickEdit: function (item) {
			this.$refs.editModal.show(item)
		},

		clickImage: function (item) {
			this.$refs.galleryModal.show(item)
		},

		clickDeleteItem: function (item) {
			this.deleteProductItem = item
			this.closeDialog = true
		},

		logout: function () {
			localStorage.removeItem("Bearer")
			this.$router.push("/")
		},

		admin: function () {
        window.open(`${process.env.VUE_APP_RETROOZ_URL}/admin`, "_blank")
		}
	},
}
</script>
