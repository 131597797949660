<template>
	<div>
		<v-dialog
			v-if="editObject"
			v-model="showDialog"
			max-width="1300"
			scrollable
		>
			<v-card>
				<v-toolbar color="primary" dark flat>
					<v-icon>mdi-file-edit-outline</v-icon>
					<v-toolbar-title class="ml-3">
						Edit product 
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn
									text
									icon
									color="white"
									@click.stop="clickUrl(editObject)"
									v-on="on"
								>
									<v-icon>mdi-web</v-icon>
								</v-btn>
							</template>
							<span>Go to product url</span>
						</v-tooltip>
					</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-container>
						<v-form ref="form">
							<v-row>
								<v-col cols="12" sm="12" md="3">
									<v-text-field
										v-model="editObject.sku"
										label="SKU"
										type="text"
										readonly
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="12" md="9">
									<v-text-field
										v-model="editObject.name"
										label="Product name"
										type="text"
										:rules="[(v) => !!v || 'Product name is required']"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="12" md="3">
									<v-checkbox
										v-model="editObject.published"
										label="Product published online"
									></v-checkbox>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.price"
										label="price"
										type="number"
										prefix="€"
										min="0"
										@keydown="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.productCost"
										label="Purchase price"
										type="number"
										prefix="€"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.stockQuantity"
										label="Stock"
										type="number"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.weight"
										label="Weight"
										type="number"
										suffix="kg"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.width"
										label="Width"
										type="number"
										suffix="cm"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.height"
										label="Height"
										type="number"
										suffix="cm"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.length"
										label="Length"
										type="number"
										suffix="cm"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="12" md="4">
									<v-select
										v-model="editObject.categoryId"
										:items="categories"
										item-text="name"
										item-value="id"
										label="Category"
										chips
										required
										:rules="[(v) => !!v || 'Category is required']"
									>
									</v-select>
								</v-col>
								<v-col cols="12" sm="12" md="4">
									<v-select
										v-model="editObject.taxCategoryId"
										:items="taxCategories"
										item-text="name"
										item-value="id"
										label="Tax category"
										chips
										required
										:rules="[(v) => !!v || 'Tax category is required']"
									>
									</v-select>
								</v-col>
								<v-col cols="12" sm="12" md="4">
									<v-combobox
										v-model="editObject.tags"
										:items="tags"
										item-text="name"
										item-value="id"
										label="Tags"
										chips
										multiple
										clearable
									>
									</v-combobox>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="editObject.stockLocation"
										label="Stock location"
										type="text"
										:rules="[(v) => !!v || 'Stock location is required']"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="editObject.shortDescription"
										label="Short product description"
										:rules="[(v) => !!v || 'Short product description']"
									></v-textarea>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="editObject.fullDescription"
										label="Long product description"
									></v-textarea>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<p>Images 
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-btn
													text
													icon
													color="grey"
													v-on="on"
												>
													<v-icon>mdi-help-circle</v-icon>
												</v-btn>
											</template>
											<span>Drag images to reorder and click  <br> "REORDER IMAGES" button below to save</span>
										</v-tooltip>
									</p>
								</v-col>
							</v-row>
							<v-row>
								<draggable
									v-model="editObject.images"
									@start="drag = true"
									@end="drag = false"
									@change="updateImageDisplayOrder($event)"
									sort="true"
									class="image-item"
								>
									<v-col
										v-for="imageInfo in editObject.images"
										:key="imageInfo.productPictureId"
										cols="12"
										md="3"
										sm="6"
									>
										<div :data-id="imageInfo.productPictureId">
											Display order: {{ imageInfo.order }}
											<v-img :src="imageInfo.link" :lazy-src="imageInfo.link">
												<v-icon
													@click="clickDeleteImage(imageInfo.productPictureId)"
													color="pink"
													left
												>
													<template v-slot:placeholder>
														<v-row
															class="fill-height ma-0"
															align="center"
															justify="center"
														>
															<v-progress-circular
																indeterminate
																color="grey lighten-5"
															></v-progress-circular>
														</v-row>
													</template>
													mdi-delete
												</v-icon>
											</v-img>
										</div>
									</v-col>
								</draggable>
								<v-col cols="12" md="3" sm="6">
									Image preview
									<v-img
										v-if="imageToUpload"
										:lazy-src="previewLink"
										:src="previewLink"
									>
										<template v-slot:placeholder>
											<v-row
												class="fill-height ma-0"
												align="center"
												justify="center"
											>
												<v-progress-circular
													indeterminate
													color="grey lighten-5"
												></v-progress-circular>
											</v-row>
										</template>
									</v-img>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-file-input
										v-model="imageToUpload"
										type="file"
										accept="image/*"
										id="file-input"
									></v-file-input>
									<v-btn outlined tile @click="uploadImage" color="primary" class="mr-5">
										<v-icon left>mdi-plus</v-icon>
										Add image
									</v-btn>
									<v-btn outlined tile @click="reorderImages"> 
										<v-icon left>mdi-swap-horizontal</v-icon>
										Reorder images
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<p>Attachments
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-btn
													text
													icon
													color="grey"
													v-on="on"
												>
													<v-icon>mdi-help-circle</v-icon>
												</v-btn>
											</template>
											<span>Drag attachments to reorder and click  <br> "REORDER ATTACHMENTS" button below to save</span>
										</v-tooltip>
									</p>
								</v-col>
							</v-row>
							<v-row>
								<draggable
									v-model="editObject.attachments"
									@start="drag = true"
									@end="drag = false"
									@change="updateAttachmentDisplayOrder($event)"
									sort="true"
									class="attachment-item"
								>
									<v-col
										v-for="attachmentInfo in editObject.attachments"
										:key="attachmentInfo.attachmentMappingId"
										cols="12"
										md="4"
										sm="6"
									>
									<v-card outlined>
										<v-card-title>{{ attachmentInfo.name }}</v-card-title>
										<v-card-text>
											<p>Display order: {{ attachmentInfo.order }}</p>
											<a :href="attachmentInfo.link" target="_blank">{{ attachmentInfo.name }}</a>
										</v-card-text>
										<v-card-actions>
											<v-btn
												color="red"
												text
												@click="clickDeleteAttachment(attachmentInfo.attachmentMappingId)"
											>
												Delete
											</v-btn>
										</v-card-actions>
									</v-card>
									</v-col>
								</draggable>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-file-input
										v-model="attachmentToUpload"
										type="file"
										accept=""
										id="file-input"
									></v-file-input>
									<v-btn outlined tile @click="uploadAttachment" color="primary" class="mr-5">
										<v-icon left>mdi-plus</v-icon>
										Add attachment
									</v-btn>
									<v-btn outlined tile @click="reorderAttachments"> 
										<v-icon left>mdi-swap-horizontal</v-icon>
										Reorder attachments
									</v-btn>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="editObject.adminComment"
										label="Internal Note"
									></v-textarea>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn outlined tile color="secondary" @click="showDialog = false">
						<v-icon left>mdi-cancel</v-icon> Cancel 
					</v-btn>
					<v-btn outlined tile color="teal" @click="submitEdit">
						<v-icon left>save</v-icon> Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="deleteImageDialog" max-width="500">
			<v-card>
				<v-card-title class="headline">
					<v-icon large left color="red"> mdi-exclamation </v-icon>
					Delete
				</v-card-title>

				<v-card-text>
					<p>Are you sure you want to delete this image?</p>
				</v-card-text>

				<v-card-actions>
					<v-spacer />

					<v-btn color="primary" text @click="showDialog = false"> No </v-btn>
					<v-btn color="red" text @click="deleteImage"> Yes </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
				<v-dialog v-model="deleteAttachmentDialog" max-width="500">
			<v-card>
				<v-card-title class="headline">
					<v-icon large left color="red"> mdi-exclamation </v-icon>
					Delete
				</v-card-title>

				<v-card-text>
					<p>Are you sure you want to delete this attachment?</p>
				</v-card-text>

				<v-card-actions>
					<v-spacer />

					<v-btn color="primary" text @click="showDialog = false"> No </v-btn>
					<v-btn color="red" text @click="deleteAttachment"> Yes </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import draggable from "vuedraggable"

export default {
	name: "EditModal",

	components: {
		draggable,
	},

	props: {
		categories: {
			type: Array,
		},
		taxCategories: {
			type: Array,
		},
		tags: {
			type: Array,
		},
	},

	data: function () {
		return {
			showDialog: false,
			editObject: null,
			deleteproductPictureId: 0,
			deleteImageDialog: false,
			imageToUpload: null,
			attachmentToUpload: null,
			deleteAttachmentMappingId: 0,
			deleteAttachmentDialog: false,
		}
	},

	computed: {
		previewLink: function () {
			if (this.imageToUpload != null) {
				return URL.createObjectURL(this.imageToUpload)
			} else {
				return ""
			}
		},
	},

	methods: {
		show: function (item) {
			if (item) {
				this.editObject = item
				this.showDialog = true
			}
		},

		getUpdatedProduct: async function () {
			try {
				await new Promise(resolve => setTimeout(resolve, 500));
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				let updatedProduct = await this.$http.get(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/GetProductById?productId=${this.editObject.id}`,
					config
				)

				this.editObject = updatedProduct.data
			} catch (error) {
				let errorMessage = `${error.response.status}: ${error.response.data}`
				this.updateSnackbar(errorMessage)
			}
		},

		// Emits snackbar message back to parent
		updateSnackbar: function (message) {
			this.$emit("snackbarUpdate", message)
		},

		updateProducts: function () {
			this.$emit("updateProducts")
		},

		updateImageDisplayOrder(event) {
			// move the item in the underlying array
			this.editObject.images.splice(
				event.newIndex,
				0,
				this.editObject.images.splice(event.oldIndex, 1)[0]
			)
			// update order properties based on position in array
			this.editObject.images.forEach(function (item, index) {
				item.order = index + 1
			})
		},

		updateAttachmentDisplayOrder(event) {
			// move the item in the underlying array
			this.editObject.attachments.splice(
				event.newIndex,
				0,
				this.editObject.attachments.splice(event.oldIndex, 1)[0]
			)
			// update order properties based on position in array
			this.editObject.attachments.forEach(function (item, index) {
				item.order = index + 1
			})
		},

		numberChange: function (event) {
			event = event ? event : window.event
			const charCode = event.which ? event.which : event.keyCode
			const ignoreChars = ["@", "#", "!", "$", "%", "^", "&", "*", "(", ")"]

			if (ignoreChars.includes(event.key)) {
				event.preventDefault()
			}

			if (
				(charCode >= 48 && charCode <= 57) ||
				(charCode >= 96 && charCode <= 105) || // number
				(charCode >= 37 && charCode <= 40) || // arrows
				charCode === 46 ||
				charCode === 8 || // backspace or delete
				charCode === 188
			) {
				return true
			} else {
				event.preventDefault()
			}
		},

		clickDeleteImage: function (id) {
			this.deleteproductPictureId = id
			this.deleteImageDialog = true
		},

		clickDeleteAttachment: function (id) {
			this.deleteAttachmentMappingId = id
			this.deleteAttachmentDialog = true
		},

		deleteImage: async function () {
			if (this.deleteproductPictureId) {
				try {
					const token = localStorage.getItem("Bearer")
					const config = {
						headers: { Authorization: `Bearer ${token}` },
					}

					await this.$http.delete(
						`${process.env.VUE_APP_RETROOZ_URL}/api/products/DeleteImage?id=${this.deleteproductPictureId}`,
						config
					)

					this.updateSnackbar("Image has been removed")
					this.deleteImageDialog = false
					this.updateProducts()
					this.getUpdatedProduct()
				} catch (error) {
					this.updateSnackbar(error)
				}
			}
		},

		deleteAttachment: async function () {
			if (this.deleteAttachmentMappingId) {
				try {
					const token = localStorage.getItem("Bearer")
					const config = {
						headers: { Authorization: `Bearer ${token}` },
					}

					await this.$http.delete(
						`${process.env.VUE_APP_RETROOZ_URL}/api/products/DeleteAttachment?id=${this.deleteAttachmentMappingId}`,
						config
					)

					this.updateSnackbar("Attachment has been removed")
					this.deleteAttachmentDialog = false
					this.updateProducts()
					this.getUpdatedProduct()
				} catch (error) {
					this.updateSnackbar(error)
				}
			}
		},

		uploadImage: async function () {
			if (!this.imageToUpload) {
				return this.updateSnackbar("upload an image first")
			}

			try {
				let data = new FormData()
				data.append("file", this.imageToUpload)
				data.append("productId", this.editObject.id)

				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				await this.$http.post(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/UploadPictures`,
					data,
					config
				)

				this.imageToUpload = null
				this.getUpdatedProduct()
				this.updateSnackbar("Image succesfully uploaded")
				this.updateProducts()
			} catch (error) {
				this.updateSnackbar(error.response)
			}
		},

		uploadAttachment: async function () {
			if (!this.attachmentToUpload) {
				return this.updateSnackbar("upload an attachment first")
			}

			try {
				let data = new FormData()
				data.append("file", this.attachmentToUpload)
				data.append("productId", this.editObject.id)

				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				await this.$http.post(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/UploadAttachment`,
					data,
					config
				)

				this.attachmentToUpload = null
				this.getUpdatedProduct()
				this.updateSnackbar("Attachment succesfully uploaded")
				this.updateProducts()
			} catch (error) {
				this.updateSnackbar(error.response)
			}
		},

		reorderImages: async function () {
			try {
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				await this.$http.post(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/UpdateImagesDisplayOrder`,
					this.editObject.images,
					config
				)
				this.updateSnackbar("Images reordered")
				this.getUpdatedProduct()
			} catch (error) {
				this.updateSnackbar(error)
			}
		},

		reorderAttachments: async function () {
			try {
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				await this.$http.post(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/UpdateAttachmentsDisplayOrder`,
					this.editObject.attachments,
					config
				)
				this.updateSnackbar("Attachments reordered")
				this.getUpdatedProduct()
			} catch (error) {
				this.updateSnackbar(error)
			}
		},

		submitEdit: async function () {
			try {
				if (this.$refs.form.validate()) {
					const token = localStorage.getItem("Bearer")
					const config = {
						headers: { Authorization: `Bearer ${token}` },
					}

					await this.$http.post(
						`${process.env.VUE_APP_RETROOZ_URL}/api/products/UpdateProduct`,
						this.editObject,
						config
					)

					this.editDialog = false
					this.updateSnackbar("Changes have been saved")
					this.updateProducts()
				} else {
					this.updateSnackbar("Please fill all required fields")
				}
			} catch (error) {
				this.updateSnackbar(error)
			}
		},

		clickUrl: function(item) {
			window.open(item.productUrl, "_blank")
		},
	},
}
</script>

<style lang="scss" scoped>
.image-item {
	display: flex;
	flex-wrap: wrap;

	.preview-alert {
		align-self: center;
	}
}

.attachment-item {
	display: flex;
	flex-wrap: wrap;

	.v-card__title {
		width: 500px;
	}
}
</style>
