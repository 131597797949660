<template>
	<div>
		<v-dialog
			v-if="editObject"
			v-model="showDialog"
			max-width="1300"
			scrollable
		>
			<v-card>
				<v-toolbar color="primary" dark flat>
					<v-icon>mdi-file-edit-outline</v-icon>
					<v-toolbar-title class="ml-3">Create new product</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<v-container>
						<v-form ref="form">
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="editObject.name"
										label="Product name"
										type="text"
										:rules="[(v) => !!v || 'Product name is required']"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="12" md="3">
									<v-checkbox
										v-model="editObject.published"
										label="Product published online"
									></v-checkbox>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.price"
										label="price"
										type="number"
										prefix="€"
										min="0"
										@keydown="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.productCost"
										label="Purchase price"
										type="number"
										prefix="€"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.stockQuantity"
										label="Stock"
										type="number"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.weight"
										label="Weight"
										type="number"
										suffix="kg"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.width"
										label="Width"
										type="number"
										suffix="cm"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.height"
										label="Height"
										type="number"
										suffix="cm"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="3">
									<v-text-field
										v-model.number="editObject.length"
										label="Length"
										type="number"
										suffix="cm"
										min="0"
										@keypress="numberChange"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="12" md="4">
									<v-select
										v-model="editObject.categoryId"
										:items="categories"
										item-text="name"
										item-value="id"
										label="Category"
										chips
										required
										:rules="[(v) => !!v || 'Category is required']"
									>
									</v-select>
								</v-col>
								<v-col cols="12" sm="12" md="4">
									<v-select
										v-model="editObject.taxCategoryId"
										:items="taxCategories"
										item-text="name"
										item-value="id"
										label="Tax category"
										chips
										required
										:rules="[(v) => !!v || 'Tax category is required']"
									>
									</v-select>
								</v-col>
								<v-col cols="12" sm="12" md="4">
									<v-combobox
										v-model="editObject.tags"
										:items="tags"
										item-text="name"
										item-value="id"
										label="Tags"
										chips
										multiple
										clearable
									>
										<!--                    <template v-if="noData" v-slot:no-data>-->
										<!--                      <v-list-item>-->
										<!--                        <v-list-item-content>-->
										<!--                          <v-list-item-title>-->
										<!--                            No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one-->
										<!--                          </v-list-item-title>-->
										<!--                        </v-list-item-content>-->
										<!--                      </v-list-item>-->
										<!--                    </template>-->
									</v-combobox>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-text-field
										v-model="editObject.stockLocation"
										label="Stock location"
										type="text"
										:rules="[(v) => !!v || 'Stock location is required']"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="editObject.shortDescription"
										label="Short product description"
										:rules="[(v) => !!v || 'Short product description']"
									></v-textarea>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="editObject.longDescription"
										label="Long product description"
									></v-textarea>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<p>
										You can add images after saving
									</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<v-textarea
										v-model="editObject.adminComment"
										label="Internal Note"
									></v-textarea>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn outlined tile color="secondary" @click="showDialog = false"> 
						<v-icon left>mdi-cancel</v-icon> Cancel 
					</v-btn>
					<v-btn outlined tile color="teal" @click="submitEdit">
						<v-icon left>mdi-plus</v-icon> Create new product
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: "CreateNewModal",

	props: {
		categories: {
			type: Array,
		},
		taxCategories: {
			type: Array,
		},
		tags: {
			type: Array,
		},
	},

	data: function () {
		return {
			showDialog: false,
			editObject: {},
		}
	},

	methods: {
		show: function (item) {
			this.editObject = item
			this.showDialog = true
		},

		getUpdatedProduct: async function () {
			try {
				const token = localStorage.getItem("Bearer")
				const config = {
					headers: { Authorization: `Bearer ${token}` },
				}

				let updatedProduct = await this.$http.get(
					`${process.env.VUE_APP_RETROOZ_URL}/api/products/GetProductById?productId=${this.editObject.id}`,
					config
				)

				this.editObject = updatedProduct.data
			} catch (error) {
				let errorMessage = `${error.response.status}: ${error.response.data}`
				this.updateSnackbar(errorMessage)
			}
		},

		// Emits snackbar message back to parent
		updateSnackbar: function (message) {
			this.$emit("snackbarUpdate", message)
		},

		openNewProduct: function (productId) {
			this.$emit("openNewProduct", productId)
		},

		numberChange: function (event) {
			event = event ? event : window.event
			const charCode = event.which ? event.which : event.keyCode
			const ignoreChars = ["@", "#", "!", "$", "%", "^", "&", "*", "(", ")"]

			if (ignoreChars.includes(event.key)) {
				event.preventDefault()
			}

			if (
				(charCode >= 48 && charCode <= 57) ||
				(charCode >= 96 && charCode <= 105) || // number
				(charCode >= 37 && charCode <= 40) || // arrows
				charCode === 46 ||
				charCode === 8 || // backspace or delete
				charCode === 188
			) {
				return true
			} else {
				event.preventDefault()
			}
		},

		submitEdit: async function () {
			try {
				if (this.$refs.form.validate()) {
					const token = localStorage.getItem("Bearer")
					const config = {
						headers: { Authorization: `Bearer ${token}` },
					}

					const response = await this.$http.post(
						`${process.env.VUE_APP_RETROOZ_URL}/api/products/CreateNewProduct`,
						this.editObject,
						config
					)

					this.showDialog = false
					this.updateSnackbar("Product has been added")
					this.openNewProduct(response.data.Id)
				} else {
					this.updateSnackbar("Please fill all required fields")
				}
			} catch (error) {
				this.updateSnackbar(error)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.image-item {
	display: flex;
	flex-wrap: wrap;

	.preview-alert {
		align-self: center;
	}
}
</style>
