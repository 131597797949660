<template>

  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-icon>mdi-account-box-outline</v-icon>
              <v-toolbar-title class="ml-3">Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-card-text class="text--primary">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam malesuada neque vel ex pellentesque, vitae.</p>
              </v-card-text>
              <v-form
                v-model="valid"
                ref="form"
                id="login-form"
                lazy-validation
              >
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    name="email"
                    prepend-icon="person"
                    type="text"
                    required
                    :rules="[v => !!v || 'Email is required']"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    required
                    :rules="[v => !!v || 'Password is required', v => (v && v.length > 6) || 'Password must be more than 6 characters']"
                  ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn outlined tile text small :to="{name: 'forgot-password'}">Forgot password?</v-btn>
              <v-btn outlined tile color="primary" form="login-form" @click.stop="submit"><v-icon left>mdi-pencil</v-icon>Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      :top="snackbarTop"
    >
      {{ snackbarText }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-main>
</template>

<style lang="scss" scoped>

</style>

<script>

    export default {
      name: 'AccountLogin',
      data() {
        return {
          snackbar: false,
          snackbarText: '',
          snackbarTop: true,
          snackbarTimeout: 3000,
          valid: false,
          user: {
            email: '',
            password: '',
          }
        }
      },

      async mounted() {   
      if (localStorage.getItem("Bearer")) {
        this.$router.push("/products-list")
        } 
      },

      methods: {
        updateSnackbar: function(message) {
          this.snackbar = true,
          this.snackbarText = message
        },

        submit: async function() {
          if (!this.$refs.form.validate()) {
            return this.updateSnackbar("Enter your user credentials")
          }

          try {
              const user = await this.$http.post(
                `${process.env.VUE_APP_RETROOZ_URL}/api/account/Login`,
                this.user
              );
              localStorage.setItem("Bearer", user.data);
              this.$router.push('products-list')

              this.updateSnackbar("Login succesful")
          } catch (error) {
            this.updateSnackbar(error.response)
          }       
        }
      }
    }
</script>