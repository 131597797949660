<template>

  <v-main>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-icon>mdi-lock-reset</v-icon>
              <v-toolbar-title class="ml-3">Forgot password</v-toolbar-title>
              <v-spacer/>
            </v-toolbar>
            <v-card-text>
              <v-card-text class="text--primary">
                <p>Your login is the same as the Retrooz webshop admin account. Click the button below to be redirected to the Retrooz webshop forgot password page.</p>
              </v-card-text>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn outlined tile text small :to="{name: 'login'}">Back to login</v-btn>
              <v-btn outlined tile color="primary" @click="redirect">Request new password</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      :top="snackbarTop"
    >
      {{ snackbarText }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </v-main>
</template>

<style lang="scss" scoped>

</style>

<script>
  export default {
    name: 'ForgotPassword',
    data() {
      return {
        valid: false,
        email: '',
        snackbar: false,
        snackbarText: '',
        snackbarTop: true,
        snackbarTimeout: 3000,
      }
    },
    methods: {
      redirect() {
        window.open(`${process.env.VUE_APP_RETROOZ_URL}/passwordrecovery`, "_blank")
      }
    }
  }
</script>