import Vue from 'vue'
import Router from 'vue-router'

import Login from './components/Login/Login.vue'
import ForgotPassword from "./components/Login/ForgotPassword";
import ProductsList from "./components/Product/List";

Vue.use(Router)


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    },
    {
      path: '/products-list',
      name: 'products-list',
      component: ProductsList
    }
  ]
})

export default router