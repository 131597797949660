<template>
	<v-dialog v-model="showDialog" max-width="500">
		<v-card v-if="editObject">
			<v-card-title class="headline">{{ editObject.sku }}</v-card-title>
			<v-card-text no-gutters>
				<v-row>
					<v-col cols="12">
						<v-img :lazy-src="dialogBigImage" :src="dialogBigImage">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular
										indeterminate
										color="grey lighten-5"
									></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						v-for="image in editObject.images"
						:key="image.id"
						cols="12"
						md="3"
						sm="3"
					>
						<a :href="image.link" @click.stop="clickImageThumb">
							<v-img :lazy-src="image.link" :src="image.link">
								<template v-slot:placeholder>
									<v-row
										class="fill-height ma-0"
										align="center"
										justify="center"
									>
										<v-progress-circular
											indeterminate
											color="grey lighten-5"
										></v-progress-circular>
									</v-row>
								</template>
							</v-img>
						</a>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer />

				<v-btn color="primary darken-1" text @click="showDialog = false">
					Close
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "GalleryModal",

	data: function () {
		return {
			dialogBigImage: "",
			showDialog: false,
			editObject: null,
		}
	},

	methods: {
		show: function (item) {
			this.editObject = item
			if (this.editObject.images.length) {
				this.showDialog = true
				this.dialogBigImage = this.editObject.images[0].link
			}
		},
		clickImageThumb: function (event) {
			event.preventDefault()
			const src = event.currentTarget.getAttribute("href")
			if (src) {
				this.dialogBigImage = src
			}
		},
	},
}
</script>

<style scoped></style>
